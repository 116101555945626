<template>
    <div class="container-fluid view-logs">

        <div class="box-header">
            <div class="row">
                <div class="col-md-4">
                    <h4 class="title-in-h">{{$t(info_page.lang_file+'.index.title') + ' - '+info_page.title}}</h4>
                </div>
            </div>
        </div>



    <div class="row">

        <vue-topprogress ref="topProgress"></vue-topprogress>
        <div class="col-12">
            <card v-loading="screenLoading" class="card-complete"
                  element-loading-lock="true"
                  element-loading-customClass="min-height-large"
                  :element-loading-text="screenText"
                  element-loading-spinner="el-icon-loading">
                <div>
                    <div class="col-12 d-flex justify-content-end  flex-wrap">
                        
                            <!--<button class="btn-primary"><span class="el-icon-search"></span></button>-->
                            <button @click="clearFilter()" class="btn button-flt btn-danger mr-3" style="">Limpiar filtros</button>
                            <button @click="searchFilter()" class="btn button-flt btn-default" style="">Buscar</button>
                        
                    </div>


                    <div class="row filter-box filter-logs float-end">

                       
                        <div class="col-md-4">
                            <label class="legend label-minus">{{$t('log.index.filter_date')}}:</label>
                            <fg-input class="minus-lbl date-range">

                                <el-date-picker
                                        v-model="filter.date_range"
                                        type="daterange"
                                        placeholder="Inicio"
                                        format="dd-MM-yyyy"
                                        range-separator="a"
                                        start-placeholder="Desde"
                                        end-placeholder="Hasta"
                                ></el-date-picker>
                            </fg-input>
                        </div>
                        <div class="col-md-4">
                            <label class="legend label-minus">{{$t('log.index.filter_date_apply')}}:</label>
                            <fg-input class="minus-lbl date-range">

                                <el-date-picker
                                        v-model="filter.date_range_apply"
                                        type="daterange"
                                        placeholder="Inicio"
                                        format="dd-MM-yyyy"
                                        range-separator="a"
                                        start-placeholder="Desde"
                                        end-placeholder="Hasta"
                                        :disabled="!(info_page.section_name==='rate' || info_page.section_name==='room')"
                                ></el-date-picker>
                            </fg-input>
                        </div>
                        <div class="col-md-2">
                            <label class="legend label-minus">{{$t('log.index.filter_action')}}:</label>

                            <fg-input type="text" v-model="filter.search_for" class="select-default">
                                <el-select v-model="filter.search_for">
                                    <el-option
                                            v-for="option in info_page.search_for"
                                            v-if="option"
                                            :value="option.value"
                                            :label="option.name"
                                    ></el-option>
                                </el-select>
                            </fg-input>
                        </div>
                        <div class="col-md-2">
                            <label class="legend label-minus">{{$t('log.index.filter_user')}}:</label>

                            <fg-input type="text" v-model="filter.user_id" class="select-default">
                                <el-select v-model="filter.user_id">
                                    <el-option value="" label="Todos"></el-option>
                                    <el-option
                                            v-for="option in info_page.users"
                                            v-if="option"
                                            :value="option._id"
                                            :label="option.email"
                                    ></el-option>
                                </el-select>
                            </fg-input>
                        </div>
                       <!-- <div class="col-md-2">
                            <label class="legend label-minus">{{$t('log.index.filter_id')}}:</label>
                            <el-input type="search"
                                      :placeholder="$t(info_page.lang_file+'.index.search') "
                                      v-model="searchQuery"
                                      :isDraggable="true"
                                      aria-controls="datatables"/>
                        </div>-->
                        
                    </div>







                    <div class="col-sm-12">

                        <el-table
                                  style="width: 100%"
                                  :data="displayData"
                                  row-key="_id"
                                  ref="table"
                                  >
                            <el-table-column type="expand" width="1">
                                <template slot-scope="props">
                                    <div class="contain-logs" v-if="props.row.data" v-html="props.row.html_response">
                                        
                                        
                                    </div>

                                </template>
                            </el-table-column>

                            <el-table-column min-width="80"  :label="$t(info_page.lang_file+'.index.date') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{transDate(row.date)}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="80"  :label="$t(info_page.lang_file+'.index.time') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                        <label>{{transTime(row.date)}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.type') ">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center">
                                           <!-- <label v-html="getVigency(row)"></label>-->
                                        <label>{{getAction(row.action)}}</label>

                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.user') ">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label>{{row.user_data.email}}</label>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.name') " v-if="info_page.section_name!=='rate'">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label>{{row.object_data.name}}</label>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.room') " v-if="info_page.section_name==='rate'">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label>{{row.room_data.name}}</label>
                                    </div>

                                </template>
                            </el-table-column>
                            <el-table-column min-width="100"  :label="$t(info_page.lang_file+'.index.rateplan') " v-if="info_page.section_name==='rate'">
                                <template slot-scope="{row}">

                                    <div class="lbl-container text-center">
                                        <label>{{row.rateplan_data.name}}</label>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column min-width="80"  :label="$t(info_page.lang_file+'.index.date_apply') "  v-if="info_page.section_name==='rate' || info_page.section_name==='room'">
                                <template slot-scope="{row}">
                                    <div class="lbl-container text-center" v-if="row.date_apply!==undefined">
                                        <label>{{transDate(row.date_apply)}}</label>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="Acciones" width="100">
                                <template slot-scope="scope">
                                    <a type="text"  @click="toogleExpand(scope.row)" v-if="scope.row.data.length" class="btn-info btn-simple btn-link color-blue-var ml-3 mr-3">
                                        <i class="fa fa-eye"></i>
                                    </a>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category" v-if="total>0">
                            {{$t('form.showing_a')}} {{from + 1}} {{$t('form.showing_b')}} {{to}}
                            {{$t('form.showing_c')}} {{total}} {{$t('form.showing_d')}} </p>
                    </div>
                    <l-pagination class="pagination-no-border"
                                  v-model="pagination.currentPage"
                                  :per-page="pagination.perPage"
                                  @input="handlePageChange"
                                  :total="pagination.total">
                    </l-pagination>
                </div>
            </card>
        </div>
    </div>

    </div>

</template>
<script>
    import { DatePicker, Table, TableColumn, Select, Option} from 'element-ui'
    import {Pagination as LPagination, Breadcrumb, BreadcrumbItem, Switch as LSwitch} from 'src/components/index'
    import swal from 'sweetalert2'
    import Fuse from 'fuse.js'
    import moment from 'moment';
    import CrudService from '../../../js/services/LogService';
    import EventBus from "../../../js/helpers/EventBus";
    import { vueTopprogress } from 'vue-top-progress'
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    const crudService = new CrudService();
    import Settings from "src/js/helpers/Settings";
    import LogFunctions from "src/js/functions/LogFunctions";
    import PropertyService from '../../../js/services/PropertyService';
    import RateplanService from '../../../js/services/RateplanService';
    import RateService from '../../../js/services/RateService';
    let settings = new Settings();
    import { mapGetters } from "vuex";

    const logFunctions = new LogFunctions();
    const propertyService = new PropertyService();
    const rateplanService = new RateplanService();
    const rateService = new RateService();

    export default {
        components: {
            LPagination,
            Breadcrumb,
            BreadcrumbItem,
            LSwitch,
            [DatePicker.name]: DatePicker,
            [Select.name]: Select,
            [Option.name]: Option,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            vueTopprogress
        },
        computed: {
            ...mapGetters(["selected_property","writting_permission"]),
            pagedData() {
                return this.tableData.slice(this.from, this.to)
            },
            /***
             * Searches through table data and returns a paginated array.
             * Note that this should not be used for table with a lot of data as it might be slow!
             * Do the search and the pagination on the server and display the data retrieved from server instead.
             * @returns {computed.pagedData}
             */
            /*queriedData() {
                let result = this.tableData
                if (this.searchQuery !== '') {
                    //result = this.fuseSearch.search(this.searchQuery)
                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                return result.slice(this.from, this.to)
            },*/
            to() {
                let highBound = this.from + this.pagination.perPage
                if (this.total < highBound) {
                    highBound = this.total
                }
                return highBound
            },
            from() {
                return this.pagination.perPage * (this.pagination.currentPage - 1)
            },
            total() {
                //this.pagination.total = this.tableData.length
                return this.pagination.total
            },

            displayData() {

                let result = JSON.parse(JSON.stringify(this.tableData))
                if (this.searchQuery !== '') {
                    var fuse = new Fuse(result, this.options);
                    result = fuse.search(this.searchQuery);
                    this.pagination.total = result.length
                }
                if (!this.tableData || this.tableData.length === 0) return [];

                if(this.pagination.activePage){
                    this.pagination.currentPage = this.pagination.activePage;
                    this.pagination.activePage = 0;
                }


                return result

            },
        },
        data() {
            return {
                screenLoading: false,
                screenText: '',
                records:[],
                filter:{
                    date_range: [new Date(new Date().setDate(new Date().getDate() - 7)),new Date()],
                    name: null,
                    search_for: '',
                    user_id: '',
                    date_range_apply: null
                },
                sectionInformation:{},
                info_page:{
                    title: '',
                    lang_file: 'log',
                    response_name: 'logs',
                    max_order: 10,
                    section_id: null,
                    section_name: null,

                    users: [],
                    search_for:[
                        {
                            value: '',
                            name: 'Todos'
                        },
                        {
                            value: 0,
                            name: 'Creación'
                        },
                        {
                            value: 1,
                            name: 'Actualización'
                        },
                        {
                            value: 2,
                            name: 'Eliminación'
                        },
                        {
                            value: 3,
                            name: 'Inicio de sesión'
                        },
                        {
                            value: 4,
                            name: 'Cerró  sesión'
                        }
                    ],
                },
                items: [],
                items_i: [],
                pagination: {
                    perPage: 30,
                    currentPage: 1,
                    perPageOptions: [5, 10, 25, 50],
                    total: 0
                },
                searchQuery: '',
                propsToSearch: ['name'],
                tableData: [],
                fuseSearch: null,
                options : {
                    shouldSort: true,
                    matchAllTokens: true,
                    threshold: 0.5,
                    location: 0,
                    distance: 100,
                    maxPatternLength: 32,
                    minMatchCharLength: 1,
                    keys: [
                        "name"
                    ]
                },
                section: 'Addon',
            }
        },
        created(){
            this.chargerItems(this.selected_property._id);
             this.$store.dispatch('CHANGE_SECTION',this.section);
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='Logs'){
                        this.chargerItems(property_id);
                    }
                })
            },

            checkedAction(){
                EventBus.$on('CHECKED_VIEW', (checked) => {
                    console.log('CHECKED_VIEW contract', this.records.length);
                    if(this.records.length){
                        this.$store.dispatch('CHANGES_ACTIVES', true);
                    }else{
                        this.$store.dispatch('CHANGES_ACTIVES', false);
                    }
                })
            },

            initLoading(text = 'Cargando...') {
                if(text==='Cargando...'){
                    this.$store.dispatch('CHANGES_ACTIVES', false);
                    this.records = [];
                }
                this.screenLoading = true;
                this.screenText = text;
            },
            async chargerItems(property_id = null){

                this.info_page.property_id = property_id;


                    //await this.getInfoProperty(property_id);
                let arrayLogs = logFunctions.getSections();



                let section_slug = this.$route.params.slug;

                let itemFinded =  arrayLogs.find(item => item.name === section_slug);
                this.sectionInformation = itemFinded;
                console.log('itemFinded', itemFinded);


                if(itemFinded){
                    this.info_page.section_id = itemFinded._id;
                    this.info_page.title = itemFinded.text;
                    this.info_page.section_name = itemFinded.name;
                }
                this.loadUsers();
                this.loadInfoSection(itemFinded);
            },
            async loadUsers (){

                let usersResponse = await propertyService.getUsersByProperty();
                usersResponse = usersResponse.data.data.users;
                usersResponse.map(item=> item.name = item.first_name+' '+item.last_name)
                this.info_page.users = usersResponse;

            },
            async loadInfoSection (itemFinded){
                this.initLoading();
                let params = {};
                let action = this.filter.search_for;
                let user_id = this.filter.user_id;
                let range_apply = this.filter.date_range_apply;
                params = {
                    property_id: this.selected_property._id,
                    section: this.info_page.section_id,
                    page: this.pagination.currentPage // Página actual para la paginación
        
                }
                if(range_apply!==null){
                    params['apply_from'] =  moment(range_apply[0], 'YYYY-MM-DD').format('YYYY-MM-DD');
                    params['apply_to'] =  moment(range_apply[1], 'YYYY-MM-DD').format('YYYY-MM-DD');
                    params['apply_to'] = params['apply_to']+' 23:59:59';
                }
                console.log('itemFinded', itemFinded);
                if(itemFinded.getProperty===false){
                    delete params.property_id;
                }
                if(action !== ''){
                    params['action'] =  action;
                }
                if(user_id !== ''){
                    params['user_id'] =  user_id;
                }               
                if(this.filter.date_range!==null){
                    params['from'] =  moment(this.filter.date_range[0], 'YYYY-MM-DD').format('YYYY-MM-DD');
                    params['to'] =  moment(this.filter.date_range[1], 'YYYY-MM-DD').format('YYYY-MM-DD');
                    params['to'] = params['to']+' 23:59:59';
                }


                let itemsResponse = await crudService.getList(params);
                this.pagination.total = itemsResponse.data.data.total;
                itemsResponse = itemsResponse.data.data.logs;
                //console.log('itemsResponse', itemsResponse);
                itemsResponse.map(item=> item.object_data.name = this.generateName(itemFinded, item.object_data));
                itemsResponse.map(item=> item.object_data.html_response = null);
                this.info_page.actives = itemsResponse.length;
                this.tableData = itemsResponse;
                this.closeLoading();


            },
            handlePageChange(newPage) {
                this.pagination.currentPage = newPage;
                let section_slug = this.$route.params.slug;

                let arrayLogs = logFunctions.getSections();
                let itemFinded =  arrayLogs.find(item => item.name === section_slug);
                this.loadInfoSection(itemFinded); // Volver a cargar los datos para la página actual
            },
            generateName(itemFinded, item){
                let item_name = '';

                if(item!==undefined){
                    itemFinded.column.forEach(element=> {
                        //console.log('element', element, item);
                        item_name = item_name + item[element] +' ';
                    });
                    if(itemFinded.lang){
                        item_name =  item.name.find(itemI=> itemI.lang ==='es').text;
                    }
                }
                return  item_name;

            },
            clearFilter(){
                this.filter ={
                    date_range: [new Date(new Date().setDate(new Date().getDate() - 7)),new Date()],
                    name: null,
                    search_for: '',
                    user_id: '',
                    date_range_apply: null
                };
                let arrayLogs = logFunctions.getSections();
                let section_slug = this.$route.params.slug;
                let itemFinded =  arrayLogs.find(item => item.name === section_slug);
                this.loadInfoSection(itemFinded);

            },

            searchFilter(){
                let arrayLogs = logFunctions.getSections();



                let section_slug = this.$route.params.slug;

                let itemFinded =  arrayLogs.find(item => item.name === section_slug);
                this.loadInfoSection(itemFinded);

            },

            transDate(date){
                let valueReturn = moment(date, 'YYYY-MM-DD').locale('es').format('DD-MMM-YYYY');
                return valueReturn;
            },
            transTime(date){
                let valueReturn = moment(date, 'YYYY-MM-DD HH:mm').locale('es').format('HH:mm');
                return valueReturn;
            },
            getAction(valueID){
                let valueReturn = this.info_page.search_for.find(item => item.value === valueID);
                return valueReturn.name;
            },

            formatMoney(value, decimals=2){
                value = Number(value)
                return '$ '+this.numberWithCommas(value);
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            async change_status(type){
                this.initLoading();
                let items = [];
                this.info_page.status = type;

                let property_id = this.selected_property._id;

                //console.log(this.info_page.actives, this.info_page.inactives)
                let params = {};


                if(type === 'active'){
                    params = {
                        property_id: property_id,
                        status: true,
                        expired: false
                    }
                }else if(type === 'caducate'){
                    params = {
                        property_id: property_id,
                        expired: true,
                        status: true,

                    }
                }else if(type === 'inactive'){
                    params = {
                        property_id: property_id,
                        status: false,
                    }
                }

                let responseRequest =  await crudService.getList(params);
                responseRequest = responseRequest.data.data[this.info_page.response_name];
                responseRequest.map(element => element.name =  element.name.find(item=> item.lang ==='es').text);
                this.info_page.actives = responseRequest.length;
                this.tableData = responseRequest;
                this.closeLoading();
            },
            consoleLog(title, info){
              console.log(title);
                console.log('---------------------------------');
                console.log(info);
                console.log('*********************************');
            },


            closeLoading(){
                this.screenLoading = false;
            },

            async toogleExpand(row) {
              let $table = this.$refs.table;
             
              await this.formatCell(row);
              $table.toggleRowExpansion(row);
                //this.consoleLog('row', row);
            },
            getLabelName(parameter){
                return logFunctions.getLabelName(parameter);
            },
            
            async formatCell(rowData){
                console.log(rowData);
                let arrayData = rowData.data;
                let stringHtml = '';
                console.log(arrayData);

                    await logFunctions.asyncForEach(arrayData, async dataItem=>{

                        stringHtml += '<div class="item-log" >'
                        stringHtml += '<label class="head-title">'+this.getLabelName(dataItem.parameter)+'</label>'
                        stringHtml += '<hr/>'
                        stringHtml += '<span class="box-mid before">'+ await this.getInputTranslate(dataItem.parameter, dataItem.old)+'</span>';
                        stringHtml += '<span class="box-mid after">'+await this.getInputTranslate(dataItem.parameter, dataItem.new)+'</span>';
                        stringHtml += '</div>'
                    })
                   

                    rowData.html_response = stringHtml;

            },
             async getInputTranslate(parameter, value){

                let sectionInformation = this.sectionInformation;

                let infoService = {};
                if(sectionInformation.name==='user'){
                    infoService.propertyService = propertyService
                }
                if(sectionInformation.name==='rateplan'){
                    infoService.rateplanService = rateplanService
                }
                if(sectionInformation.name==='landing'){
                    infoService.rateplanService = rateplanService
                }
                if(sectionInformation.name==='promotion'){
                    infoService.rateService = rateService
                }
                if(sectionInformation.name==='rule'){
                    infoService.rateService = rateService
                }

                if( sectionInformation.labelTranslate && sectionInformation.labelTranslate.length > 0){
                    let sectionFinded = sectionInformation.labelTranslate.find(item => item.name === parameter);

                    if(sectionFinded){
                        value =   await logFunctions.getInputTranslate(sectionFinded, value, infoService)
                       
                    }
                   
                }
                return value;
            
            }

        },
        mounted() {
            this.changeProperty()
            this.checkedAction()
        }
    }
</script>
<style lang="scss">
    .el-table{
        table{
            .cell{
                word-break: break-word;
                .lbl-container{
                    label{
                        word-break: break-word;
                    }
                    .select-default{
                        width: 60px;
                    }
                }
            }
        }

    }
    .filter-logs{
        .form-group{
            margin-bottom: 0;
        }
        button{
            margin-bottom: 0;
        }
    }
    .custom-lbl{
        label{
            color: #23CCEF;
            font-weight: 600;
        }
        span{
            width: 100%;
            display: inline-block;

        }
    }
    .content-table label{
        display: inline-block;
        width: 25%;
        font-size: 11px;
        text-transform: unset;
        order: 2;
        &:first-child{
            order: 3;
        }
    }
    .content-table label span{
        font-weight: 600;
    }
    .item-rule{
        overflow-x: hidden;
        .pl-c1{
            padding-left: .2rem;
        }
        .pr-c1{
            padding-right: .2rem;

        }
        &.header-r{
            span{
                line-height: 28px;
                height: 30px;

            }
        }
        span{
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 0;
            float: left;
            display: inline-block;
            height: 20px;
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            color: #9A9A9A;

        }
    }
    .card{
        min-height: 80vh;
        .label-row{
            white-space: nowrap;
            line-height: 16px;
            margin-bottom: 0;
            float: left;
        }
    }
    .bol-1{
        border-left: 1px solid #bebebe;
    }
    .bor-1{
        border-right: 1px solid #bebebe;
    }
    .bot-1{
        border-top: 1px solid #bebebe;
    }
    .bob-1{
        border-bottom: 1px solid #bebebe;
    }
    .box-custom{
        position: relative;
        .bootstrap-switch{
            margin-left: 15px;
            margin-right: 15px;
        }

        .icon-left{
            line-height: 23px;
            /*left: 0;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            line-height: 30px;

*/
        }
    }
    .icon-right{
        line-height: 23px;
        margin-left: 15px;
        margin-right: 15px;
        width: 60px;
        display: inline-block;
        text-align: center;
    }
    .filter-box{

        .select-default {
            .el-input:hover {
                color: #0e0e0e;
                input {
                    color: #0e0e0e !important;
                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #888888;
                    }
                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #888888;
                    }
                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: #888888;
                    }
                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: #888888;
                    }
                }
            }
            input.el-input__inner {
                background: #fff;
            }
            .el-select__caret {
                line-height: 30px;
            }
        }
    }
.contain-logs{
    .item-log{
        width: 100%;
        .head-title{
            display: block;
            font-weight: 600;
            font-size: 12px;
        }

        .box-mid{
            display: inline-table;
            width: 50%;
            padding: 5px 15px;
            font-size: 12px;
            &.after{
                color: #00AB69;
            }
            &.before{
                color: #F24957;
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }
    hr{
        margin: 5px 0;
    }
}
    .view-logs{
        .el-table__expand-icon{
            display: none;
        }
        td.el-table__expanded-cell {
            background: #fbfbfb;
        }
    }
</style>
